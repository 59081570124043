<template>
	<v-container>
		<v-row>
			<v-col
				><admin-default-header>{{
					quizName
				}}</admin-default-header></v-col
			>
		</v-row>
		<v-row>
			<v-col>
				<admin-default-description>
					Lorem, ipsum dolor sit amet consectetur adipisicing elit.
					Ipsam maiores vero quo nemo accusantium quae delectus a illo
					laudantium pariatur, soluta velit repellendus iusto deserunt
					perferendis odit, facere, magni veniam.
				</admin-default-description></v-col
			>
			<v-col class="d-flex flex-row align-start justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					@click="goTo('QuizEditor')"
					>Edit Quiz</v-btn
				>

				<v-btn
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					@click="goTo('QuizAssign')"
					>Assign to Course</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<span class="mr-4">Created by: {{ createdBy }}</span>
				<span class="mr-4">Last edited: {{ lastUpdate }}</span>
				<span class="mr-4">Date created: {{ createdDate }} </span>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<article class="d-flex flex-row justify-space-between">
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader
							>Total Courses Assigned</admin-default-subheader
						><admin-default-header>{{
							totalCoursesAssgigned
						}}</admin-default-header>
					</figure>
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader>Passes</admin-default-subheader
						><admin-default-header>{{
							passes
						}}</admin-default-header>
					</figure>
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader
							>Failures</admin-default-subheader
						><admin-default-header>{{
							fails
						}}</admin-default-header>
					</figure>

					<figure class="d-flex flex-column align-start">
						<admin-default-subheader
							>Approval Rate</admin-default-subheader
						><admin-default-header>{{
							approvalRate
						}}</admin-default-header>
					</figure>
				</article>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<admin-default-subheader class="mb-8"
					>All Courses Assign to this Quiz</admin-default-subheader
				>
				<QuizAnalyticsRowData
					v-for="course in courses"
					:key="course.id"
					:course="course"
					@getCourseData="getCourseData"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { get } from "@/util/requests/get";
import { mapGetters } from "vuex";
import QuizAnalyticsRowData from "./components/QuizAnalyticsRowData.vue";

export default {
	name: "CourseAnalytics",
	components: {
		QuizAnalyticsRowData
	},
	computed: {
		...mapGetters(["isLoading"]),
		totalCoursesAssgigned() {
			if (!!this.topStats === null) return;
			return this.topStats?.assigned_courses;
		},
		passes() {
			if (!!this.topStats === null) return;
			return this.topStats?.passes;
		},
		fails() {
			if (!!this.topStats === null) return;
			return this.topStats?.fails;
		},
		approvalRate() {
			if (!!this.topStats === null) return;
			return this.topStats?.approval_rates;
		},
		quizName() {
			if (!!this.topStats === null) return;
			return this.topStats?.name;
		},
		createdBy() {
			return "";
		},
		lastUpdate() {
			if (!!this.quiz === null) return;
			return Math.ceil(
				Number(new Date() - new Date(this.quiz?.updated_at)) /
					1000 /
					60 /
					60 /
					24
			);
		},
		createdDate() {
			if (!!this.quiz === null) return;
			return new Date(
				this.quiz?.created_at.slice(0, 10)
			).toLocaleDateString();
		}
	},
	data() {
		return {
			tabs: [
				{ title: "All", component: "CourseAnalyticsAll" },
				{ title: "Fails", component: "CourseAnalyticsFails" },
				{
					title: "Not Started",
					component: "CourseAnalyticsNotStarted"
				},
				{ title: "Passes", component: "CourseAnalyticsPasses" },
				{ title: "Taking", component: "CourseAnalyticsTaking" }
			],
			currentItem: "",
			topStats: null,
			quiz: null,
			courses: null
		};
	},
	methods: {
		goTo(val) {
			this.$router.push({
				name: val,
				id: this.$route.params.id
			});
		},
		async getCourseData(id) {
			const {
				data: { data }
			} = await get(`/quiz/${id}`);
			this.quiz = data;
			this.courses = await Promise.all(
				data.courses.map(
					async el => await this.getCourseAnalitics(el.course, el.id)
				)
			);
		},
		async getTopQuizStats(id) {
			const {
				data: { data }
			} = await get(`/quiz_analytics/${id}`);
			this.topStats = data;
		},
		async getCourseAnalitics(course, courseQuizId) {
			const {
				data: { data }
			} = await get(`/course_analytics/${course.id}`);
			return { ...data, courseQuizId: courseQuizId };
		}
	},
	async created() {
		await this.$store.dispatch("setIsLoading", true);
		await this.getTopQuizStats(this.$route.params.id);
		await this.getCourseData(this.$route.params.id);
		await this.$store.dispatch("setIsLoading", false);
	}
};
</script>

<style></style>
